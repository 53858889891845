import React from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { Grid } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { SecondaryEntryCard } from '../../entry-cards/SecondaryEntryCard';
import { RelatedArticleType } from '../../../../../types/articleType';

type Props = {
  block: {
    secondaryEntries: RelatedArticleType[];
  };
};

const SecondaryEntriesBlock = ({ block }: Props) => {
  const entries = block.secondaryEntries;
  if (!entries || entries.length === 0) return null;
  return (
    <div>
      <Text tag="h2" variant={TextVariant.Header2}>
        Hva søker du informasjon om?
      </Text>
      <SecondaryRow>
        {entries.map((entry) => (
          <SecondaryEntryCard key={`${entry.name}-${entry.slug}`} entry={entry} />
        ))}
      </SecondaryRow>
    </div>
  );
};

const SecondaryRow = styled(Grid)`
  margin-bottom: ${spacing.space40};

  @media (max-width: ${breakpoints.m}) {
    padding-left: 0;
    margin-bottom: 0;
    grid-row-gap: ${spacing.space20} !important;
  }
`;

export default SecondaryEntriesBlock;
